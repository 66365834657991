<script lang="ts" setup>
import Arrow from "@/assets/icons/arrow.svg?component";

defineEmits<{
  (e: "changeRoute", link: string): void;
}>();

const props = withDefaults(
  defineProps<{
    ctaButtonType?: "primary" | "secondary" | "tertiary";
    hasCta?: boolean;
    link?: string;
    outerSpace?: boolean;
    targetBlank?: boolean;
    title?: string;
  }>(),
  {
    outerSpace: true,
  },
);
</script>
<template>
  <section
    class="pb-10 pt-5.5"
    :class="{
      'md:pb-15 lg:pb-25': !$slots.cta && !props.hasCta,
    }"
  >
    <div
      class="container"
      :class="{
        'outer-space': outerSpace,
      }"
    >
      <slot name="title">
        <module-block-headline
          v-if="props.title && !props.hasCta"
          :title="props.title"
          :link="props.link"
          @click="$emit('changeRoute', props.link)"
        />
      </slot>
    </div>
    <div
      class="container md:mt-10 lg:mt-15"
      :class="{
        'outer-space': outerSpace,
      }"
    >
      <slot />
    </div>
    <div
      v-if="$slots.cta || props.hasCta"
      class="container mt-10 text-center lg:mt-15"
      :class="{
        'outer-space': outerSpace,
      }"
    >
      <slot name="cta">
        <base-button
          :to="props.link"
          :target="props.targetBlank ? '_blank' : ''"
          transparent
          :type="ctaButtonType ?? 'secondary'"
          class="w-full justify-center xs:w-auto"
          >{{ props.title }}<template #icon-right><Arrow /></template
        ></base-button>
      </slot>
    </div>
  </section>
</template>
