export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  // Check if running client side and Matomo env variable enabled
  if (import.meta.client && config.public.matomo_enabled) {
    // Standard Matomo JS embed code start
    const _paq = (window._paq = window._paq || []);
    _paq.push(["disableCookies"]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      const u = nuxtApp.$config.public.matomo_host;
      _paq.push(
        ["setTrackerUrl", u + "matomo.php"],
        ["setSiteId", nuxtApp.$config.public.matomo_site_id],
      );
      const d = document;
      const g = d.createElement("script");
      const s = d.querySelectorAll("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
    })();
    // Standard Matomo JS embed code end
  }
});
