<template>
  <div>
    <NuxtErrorBoundary>
      <ModuleHeader
        :key="$route.path"
        :navigation-items="result"
        class="fixed top-0 z-20 h-17 w-full"
      />
      <NuxtLoadingIndicator color="#1ADF69" />
      <NuxtPage />
      <template #error="{ error }">
        <ModuleError
          :error="error"
          @close-error="(link: string) => recoverFromError(error, link)"
        />
      </template>
      <ModuleFooter />
    </NuxtErrorBoundary>
  </div>
</template>
<script lang="ts" setup>
// init sever, get all pages and get/set locale
import type { GetNavigationQuery } from "@/types/graphql";
import getNavigation from "@/graphql/navigation.query.graphql";

// get locale for language switch
const { locale } = useI18n();

// get pages
const { result, refetch } = await useQuery<GetNavigationQuery>(getNavigation, {
  locale: locale.value,
});

const recoverFromError = async (error: any, link: string) => {
  await navigateTo(link, { external: true });
};

watch(locale, async () => {
  await refetch({ locale: locale.value });
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
