<script setup lang="ts">
import Close from "@/assets/icons/x.svg?component";
import Plus from "@/assets/icons/plus.svg?component";

import type { GetNavigationQuery } from "@/types/graphql";

defineProps<{
  navigationItems: GetNavigationQuery | null;
}>();

defineEmits<{
  (e: "changeRoute", link: string): void;
  (e: "close"): void;
}>();

const localeRoute = useLocaleRoute();

// watch(
//   () => route.params.slug,
//   () => {
//     emit("changeRoute");
//   }
// );
</script>

<template>
  <div
    class="extended-menu menu-expanded fixed top-0 z-30 h-full w-full overflow-auto"
  >
    <div class="grid min-h-full grid-cols-1 md:grid-cols-[2fr_1fr]">
      <div
        class="outer-space hidden h-fit min-h-full w-full bg-light-gray md:block"
      >
        <div class="mt-39 flex flex-wrap gap-x-4 gap-y-20 text-s">
          <ul
            v-for="(menuItem, index) in navigationItems?.pageCategories?.data"
            :key="`${menuItem.__typename}-${index}`"
            class="min-w-[14rem] text-normal"
          >
            <h4 class="p-2 pl-0 pt-0 text-gray">
              {{ menuItem.attributes?.displayName }}
            </h4>
            <li
              v-for="(route, indexRoute) in menuItem.attributes?.pages?.data"
              :key="`${route.__typename}-${indexRoute}`"
              class="font-nav-links p-2 pl-0 text-integer-black"
            >
              <NuxtLink
                :to="localeRoute(`/${route.attributes?.Slug}`)"
                class="text-normal"
                @click="$emit('changeRoute', route.attributes?.Slug)"
              >
                {{ route.attributes?.short_title ?? route.attributes?.Name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="outer-space h-full w-full bg-integer-black py-5 text-off-white"
      >
        <div class="flex">
          <div
            class="ml-auto flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-off-white"
            @click="$emit('close')"
          >
            <Close />
          </div>
        </div>
        <!-- navbar on big viewports -->
        <div class="mt-25 hidden flex-col gap-10 md:flex">
          <nuxt-link
            v-for="(item, index) in navigationItems?.mainNavigation?.data
              ?.attributes?.pages?.data"
            :key="`${item.__typename}-${index}`"
            :to="localeRoute(`/${item.attributes?.Slug}`)"
            class="text-h2 text-gray hover:text-off-white"
            @click="$emit('changeRoute', item.attributes?.Slug)"
          >
            {{ item.attributes?.Name }}
          </nuxt-link>
        </div>

        <!-- navbar on small viewports -->
        <div class="mt-25 flex flex-col gap-10 md:hidden">
          <ModuleAccordionItem
            v-for="(category, index) in navigationItems?.pageCategories?.data"
            :key="`${category.__typename}-${index}`"
            class="menu-accordion text-h2 text-gray"
          >
            <template #header="{ expand }">
              <span :class="{ 'text-off-white': expand }">
                {{ category.attributes?.displayName }}
              </span>
            </template>
            <template #icon="{ expand }">
              <Plus
                class="rotateIcon h-5 w-5"
                :class="{ 'open text-off-white': expand }"
              />
            </template>
            <template #default>
              <div class="mt-7 flex flex-col">
                <nuxt-link
                  v-for="(categoryItem, categoryIndex) in category.attributes
                    ?.pages?.data"
                  :key="`${categoryItem.__typename}-${categoryIndex}`"
                  class="ml-4 text-h3 leading-[2.75rem] hover:cursor-pointer hover:text-off-white"
                  :to="`/${categoryItem.attributes?.Slug}`"
                  @click="$emit('changeRoute', categoryItem.attributes?.Slug)"
                >
                  {{
                    categoryItem.attributes?.short_title ??
                    categoryItem.attributes?.Name
                  }}
                </nuxt-link>
              </div>
            </template>
          </ModuleAccordionItem>

          <nuxt-link
            v-for="(item, index) in navigationItems?.mainNavigation?.data
              ?.attributes?.pages?.data"
            :key="`${item.__typename}-${index}`"
            :to="localeRoute(`/${item.attributes?.Slug}`)"
            class="text-h2 text-gray hover:text-off-white"
            @click="$emit('changeRoute', item.attributes?.Slug)"
          >
            {{ item.attributes?.Name }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.x {
  filter: invert(1);
}

.rotateIcon {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}
.rotateIcon.open {
  transform: rotate(45deg);
  transition: transform 200ms linear;
}
</style>

<style>
.menu-accordion > .accordion-header {
  @apply hover:text-off-white;
}
</style>
