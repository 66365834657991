<template>
  <div class="module-accordion-item">
    <div
      class="accordion-header flex cursor-pointer items-center"
      @click="expand = !expand"
    >
      <slot :expand="expand" name="header" />
      <div v-if="$slots.icon" class="icon ml-auto">
        <slot name="icon" :expand="expand" />
      </div>
    </div>

    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-show="expand" class="accordion-body">
        <slot :expand="expand" />
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
// https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
import { defineComponent, ref } from "vue";
export default defineComponent({
  data: () => ({
    expand: false,
  }),
  methods: {
    enter(element: any) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element: any) {
      element.style.height = "auto";
    },
    leave(element: any) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
});
</script>
<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.expand-enter-active,
.expand-leave-active {
  @apply default-transition transition-all;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
