import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import meta_5aLvpOrsZz from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/meta.mjs";
import i18n_yfWm7jX06p from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/usr/src/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/usr/src/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import matomo_client_hZHcd59ZPU from "/usr/src/app/plugins/matomo.client.js";
import scrollbehaviour_client_iEH8Vhn7ng from "/usr/src/app/plugins/scrollbehaviour.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  meta_5aLvpOrsZz,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  plugin_eTVJQYlCmx,
  matomo_client_hZHcd59ZPU,
  scrollbehaviour_client_iEH8Vhn7ng
]