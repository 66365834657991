export const useHeaderColor = (color: "black" | "white") => {
  onMounted(() => {
    const body = document.querySelector("body");
    body?.classList.remove("header-white");
    body?.classList.remove("header-black");
    body?.classList.add(`header-${color}`);
  });
};

interface ILocalizationState {
  de: { pathMatch: Array<string | undefined> };
  en: { pathMatch: Array<string | undefined> };
}

export const useLocalization = () =>
  useState<ILocalizationState>("localization", () => ({
    de: { pathMatch: [] },
    en: { pathMatch: [] },
  }));
