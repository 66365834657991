export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();

  // Check if running client side and Matomo env variable enabled
  if (import.meta.client && config.public.matomo_enabled) {
    // Track page view on route change
    const _paq = (window._paq = window._paq || []);
    _paq.push(["setCustomUrl", to.fullPath]);
    _paq.push(["trackPageView"]);
  }
});
