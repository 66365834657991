import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as datenschutzBjdGXvboxrMeta } from "/usr/src/app/pages/datenschutz.vue?macro=true";
import { default as impressum8rTlfYuIk3Meta } from "/usr/src/app/pages/impressum.vue?macro=true";
import { default as imprintgLccbnSD4KMeta } from "/usr/src/app/pages/imprint.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as privacy_45policy1IN0o4tDn5Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
export default [
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "datenschutz___de",
    path: "/datenschutz",
    component: () => import("/usr/src/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "datenschutz___en",
    path: "/datenschutz",
    component: () => import("/usr/src/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "impressum___de",
    path: "/impressum",
    component: () => import("/usr/src/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___en",
    path: "/impressum",
    component: () => import("/usr/src/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "imprint___de",
    path: "/imprint",
    component: () => import("/usr/src/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "imprint___en",
    path: "/imprint",
    component: () => import("/usr/src/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  }
]