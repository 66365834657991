<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: "primary" | "secondary" | "tertiary";
    size?: "small" | "medium" | "large";
    disabled?: boolean; // If true, it will disable the button
    isButton?: boolean; // If true, it will render a button instead of a link
    onlyIcon?: boolean; // If true, it will render only the icon
    transparent?: boolean; // If true, it will render a transperent button
    to?: string;
    outline?: "white" | "gray" | "black";
    external?: boolean;
  }>(),
  {
    type: "primary",
    size: "medium",
    disabled: false,
    onlyIcon: false,
    isButton: true,
    transparent: false,
  },
);
const slots = useSlots();

const styles = computed(() => ({
  "button-primary": props.type === "primary",
  "button-secondary": props.type === "secondary",
  "button-tertiary": props.type === "tertiary",
  "button-small": props.size === "small",
  "button-medium": props.size === "medium",
  "button-large": props.size === "large",
  "button-disabled": props.disabled,
  "button-only-icon": props.onlyIcon,
  "button-is-button": props.isButton,
  "button-no-text": !slots.default,
  "button-transparent": props.transparent,
  "button-outline-white": props.outline === "white",
  "button-outline-gray": props.outline === "gray",
  "button-outline-black": props.outline === "black",
}));
</script>
<template>
  <nuxt-link
    v-if="to"
    class="button inline-flex items-center gap-2"
    :class="styles"
    :to="to"
    :external="external"
  >
    <slot name="icon-left" />
    <template v-if="$slots.default">
      <span><slot /></span>
    </template>
    <slot name="icon" />
    <slot name="icon-right" />
  </nuxt-link>
  <button v-else class="button inline-flex items-center gap-2" :class="styles">
    <slot name="icon-left" />
    <template v-if="$slots.default">
      <slot />
    </template>
    <slot name="icon" />
    <slot name="icon-right" />
  </button>
</template>
<style scoped>
.button-no-text > :deep(*) {
  @apply default-transition block -translate-x-5.2 transform group-hover:translate-x-5.2;
}
</style>
